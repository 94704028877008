import React from "react"
import Stack from "./stack"
import Inline from "./inline"
import Heading from "./heading"
import Text from "./text"
import Icon from "./icon"
import { answerStatus } from "../store/courses"

const EvaluationStacked = ({ answers }) => {
  const statusIcon = {
    [answerStatus.VALID_CHECKED]: <Icon icon="done" color="positive" />,
    [answerStatus.INVALID]: <Icon icon="close" color="negative" />,
  }

  const renderAnswer = ({ index, status, answer }) => {
    return (
      <Inline key={`answer_${index}`} alignY="center" space={2}>
        {statusIcon[status.status]}
        <Text>{answer}</Text>
      </Inline>
    )
  }

  return (
    <Stack space={3}>
      <Heading>Deine Antwort</Heading>
      {answers &&
        Object.entries(answers)
          .filter(([key, value]) =>
            [answerStatus.VALID_CHECKED, answerStatus.INVALID].includes(
              value.status
            )
          )
          .map(([answer, status], index) =>
            renderAnswer({ index, status, answer })
          )}
    </Stack>
  )
}

export default EvaluationStacked
