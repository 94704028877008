import React from "react"
import Stack from "./stack"
import Inline from "./inline"
import Heading from "./heading"
import Text from "./text"

const SolutionsStacked = ({ solutions }) => {
  return (
    <Stack space={3}>
      <Heading>Lösung</Heading>
      {solutions.map((solution) => (
        <Inline key={`solution_${solution}`} alignY="center" space={2}>
          <Text>{solution}</Text>
        </Inline>
      ))}
    </Stack>
  )
}

export default SolutionsStacked
