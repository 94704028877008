import React from "react"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import EvaluationStacked from "../../../../../components/evaluation-stacked"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import Grid from "../../../../../components/grid"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import SolutionsStacked from "../../../../../components/solutions-stacked"
import Stack from "../../../../../components/stack"
import Divider from "../../../../../components/divider"

const Page = () => {
  const { getTask, answerStatusForArray, getStatsForTaskWithArray } =
    useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const taskProps = {
    courseId: "versteckte-ordnung",
    chapterId: "02-ordnung",
    taskId: "trichter",
  }
  const answers = answerStatusForArray(taskProps)
  const statistics = getStatsForTaskWithArray(taskProps)
  const solutions = getTask(taskProps).solution

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/versteckte-ordnung/02-ordnung/feuerreiter"
        />
      }
    >
      <Seo title="Trichter" />
      <Box>
        <Stack>
          <FeedbackHeaderProgress
            variant="solution"
            correct={statistics.correct}
            total={statistics.correct + statistics.missed}
          />
          <Grid columns={[1, 2]}>
            <EvaluationStacked answers={answers} />
            <SolutionsStacked solutions={solutions} />
          </Grid>
          <Divider size={4} />
          <Grid columns={[1, 2]} reverse={[false, true]}>
            <Paragraph>
              Christian Morgenstern hat seine Verse wie einen Trichter
              angeordnet. Sie werden nach unten hin immer kürzer. Außderm hat er
              darin zwei Reime versteckt: »Nacht« reimt sich auf »Schacht« und
              »heiter« auf die Abkürzung (u.)nd (s.)o (w.)eiter.
            </Paragraph>
            <PaperStack>
              <Poem align="center" size={[3, 3, 4]}>
                <p>Zwei Trichter wandeln durch die Nacht.</p>
                <p>Durch ihres Rumpfs verengten Schacht</p>
                <p>fließt weißes Mondlicht</p>
                <p>still und heiter</p>
                <p>auf ihren</p>
                <p>Waldweg</p>
                <p>u.s.</p>
                <p>w.</p>
              </Poem>
            </PaperStack>
          </Grid>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
